<!-- 景区 -->
<template>
  <div class="scenic">
    <!-- 地图 -->
    <div id="container"></div>
    <!-- 中间标题 -->
    <div class="centerTitle">水上唐诗之路</div>
    <!-- 右边返回主页 -->
    <router-link to="/" class="backIndex"></router-link>
    <!-- 左侧弹出层 -->
    <div class="leftSelect">
      <div class="iconSelect">
        <div class="icon-change" @click="leftShowFn"></div>
        <div class="icon-pio">
          <div
            :class="['pio', scenicTabActive == index ? 'pioActive' : '']"
            v-for="(item, index) in scenicTabData"
            :key="index"
          ></div>
        </div>
      </div>
      <div class="scenicName" ref="scenicName">
        <div
          :class="[
            'nameSort',
            scenicTabActive == index ? 'scenicTabActive' : '',
          ]"
          v-for="(item, index) in scenicTabData"
          :key="index"
          @click="changeScenicFn(index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 底部路线简介 -->
    <router-link to="/introduce" class="buttomLine">路线简介</router-link>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      mapObj: null, //初始化地图
      show: false, //弹出的内容
      scenicTabActive: 0,
      scenicTabData: [
        { name: "空中唐诗之路" },
        { name: "水上唐诗之路" },
        { name: "陆路唐诗之路" },
        { name: "云端唐诗之路" },
      ],
      initWidth: 0, //折叠面板内容初始宽度
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //初始化地图
    initMap() {
      this.mapObj = new AMap.Map("container", {
        view: new AMap.View2D({
          // 创建地图二维视口
          center: [116.395577, 39.892257],
          zoom: 11, //设置地图初始的的缩放级别
          rotation: 0, //设置地图旋转角度
        }),
        features: [], //去除高德的瓦片
        zooms: [2, 18], //地图可缩放的级别
        lang: "zh_cn", //设置地图语言类型，默认：中文简体
      });
    },
    //点击左侧图标弹出事件
    leftShowFn() {
      const liCon = this.$refs.scenicName;
      let width = liCon.offsetWidth;
      if (width == this.initWidth) {
        liCon.style.width = "9rem";
        width = liCon.style.width;
      } else {
        liCon.style.width = "0rem";
      }
    },
    //点击切换景区
    changeScenicFn(index) {
      this.scenicTabActive = index;
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.initMap(); //初始化地图
  },
};
</script>
<style lang="less">
.scenic {
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;
  //地图
  #container {
    width: 100%;
    height: 100%;
    position: relative;
    // 去除地图logo
    .amap-logo {
      display: none !important;
    }
    .amap-copyright {
      opacity: 0;
    }
  }
  //中间标题
  .centerTitle {
    width: 20rem;
    height: 5rem;
    background: cornflowerblue;
    text-align: center;
    line-height: 5rem;
    font-size: 3rem;
    font-weight: 500;
    position: fixed;
    top: 3rem;
    left: 50%;
    margin-left: -10rem;
  }
  //右边返回主页
  .backIndex {
    width: 3rem;
    height: 3rem;
    background: #fff;
    border-radius: 0.5rem;
    position: fixed;
    top: 10rem;
    right: 2rem;
  }
  //左侧弹出层
  .leftSelect {
    height: 15rem;
    background: #000;
    opacity: 0.5;
    position: fixed;
    left: 0;
    top: 30rem;
    .iconSelect {
      width: 3rem;
      height: 15rem;
      float: right;
      .icon-change {
        width: 3rem;
        height: 3rem;
        background: chartreuse;
      }
      .icon-pio {
        width: 100%;
        height: 12rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .pio {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          background: #fff;
        }
        .pioActive {
          background: rgb(77, 16, 85);
        }
      }
    }
    .scenicName {
      width: 0rem;
      height: 12rem;
      margin-top: 3rem;
      overflow: hidden;
      float: left;
      transition: width 0.3s;
      .nameSort {
        width: 100%;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        color: #fff;
      }
      .scenicTabActive {
        color: blueviolet;
      }
    }
  }
  //底部路线简介
  .buttomLine {
    width: 10rem;
    height: 4rem;
    position: fixed;
    left: 50%;
    margin-left: -5rem;
    bottom: 3rem;
    background: #fff;
    text-align: center;
    line-height: 4rem;
    font-size: 2rem;
  }
}
</style>